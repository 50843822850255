@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  text-align: center;
}

h5 {
  color: #1a1a40;
  font-size: 1.15rem;
}

.TodoWrapper {
  background: #fff;
  margin-top: 1rem;
  border-radius: 5px;
}

.TodoForm {
  width: 100%;
}

.todo-input {
  outline: none;
  background: none;
  border: 1px solid #8758ff;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 200px;
  color: #1a1a40;
}

.todo-input::placeholder {
  color: #1a1a40;
}

.todo-btn {
  background: #8758ff;
  color: #1a1a40;
  border: none;
  padding: 0.55rem;
  cursor: pointer;
}

.Todo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #8758ff;
  color: #1a1a40;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.Todo p {
  font-weight: bolder;
  color: #1a1a40;
  font-size: 1rem;
}

.fa-trash {
  margin-left: 0.75rem;
}

.completed {
  color: #c5aeff;
  text-decoration: line-through;
}

.part1 {
  margin-top: 10px;
  position: sticky;
  top: 0px;
  margin-bottom: 1px;
  background-color: #fff;
  z-index: 2;
}
.part2 {
  margin-top: 1px;
  z-index: 1;
}
